var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f3465af5115e57d25807597ef2bd954ce0ef1854"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import env from "@/env"
import * as Sentry from "@sentry/nextjs"

const SENTRY_DSN = env.sentryDsn
const ENV_NAME = env.deploymentEnv

if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 0.1,
        environment: ENV_NAME,
        replaysSessionSampleRate: ENV_NAME === "local" ? 1.0 : 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new Sentry.Replay({
                networkDetailAllowUrls: [
                    window.location.origin,
                    /^https:\/\/api\.heroify\./,
                    /^https:\/\/new-api\.heroify\./,
                ],
            }),
        ],

        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    })
}
